<template>
  <div class="model-main-container">

    <v-toolbar dense>
      <CopyClipboardComponent
        :toCopy="model"
        :tooltipText="'Copy model to clipboard'"
        :disabled="displayError || loading"
        :color="'white'"
        :iconSmall="false"
      >
      </CopyClipboardComponent>

      <v-toolbar-title>Model intruction strat id : {{$route.params.instructionStratId}}</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <div v-if="loading" class="loading-container">
      <div>Model is loading</div>
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      >

      </v-progress-circular>
    </div>
    <v-row>
      <v-col cols="6" class="model-container">
        <div
          v-if="!loading && !displayError"
          class="elevation-2 model-div background-code"
          v-html="computedModel"
        >
        </div>
      </v-col>
      <v-col cols="6">
        <div class="data-model-container" v-if="!loading && !displayError">
          <v-data-iterator
            :items="[instructionInfo]"
            :loading="loading"
            hide-default-footer
          >
            <template v-slot:item="props">
              <v-card>
                <v-list>

                  <v-list-item>
                    <v-list-item-content>
                      DSP
                    </v-list-item-content>
                    <v-list-item-content class="align-end">
                      <v-avatar class="img-data-table" :size="40" tile>
                        <img
                          :src="require('../assets/' + dsp + '.png')"
                          alt="dsp image"
                        />
                      </v-avatar>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      {{getDspClientLabel}}
                    </v-list-item-content>
                    <v-list-item-content class="align-end">
                      <a style="text-decoration: none;" target="_blank" rel="noopener noreferrer" :href="getUrlKeystoneSearch(clientId)">
                        {{clientId}}
                      </a>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      Advertiser Id
                    </v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{props.item.strat.advertiser_id}}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      {{getInsertionOrderLabelPerIo}}
                    </v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{props.item.strat[getInsertionOrderPropertyPerIo]}}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      {{lineItemLabel}}
                    </v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{props.item.strat[lineItemField]}}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="dsp === $APPNEXUS">
                    <v-list-item-content>
                      Campaign
                    </v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{props.item.strat.campaign_id}}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      Insertion Date
                    </v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{$formatDate(props.item.strat.insertion_date)}}
                    </v-list-item-content>
                  </v-list-item>

                </v-list>
              </v-card>
            </template>
          </v-data-iterator>
        </div>
      </v-col>
    </v-row>

    <v-alert
      v-model="displayError"
      type="error"
    >
      {{errors}}
    </v-alert>
  </div>
</template>

<script>
import { stratMixin } from '../mixins/stratMixin'
import { generalMixin } from '../mixins/generalMixin'
import store from '../../store/index.js'
import { overlay } from '../../store/modules/overlay.js'
import { users } from '../../store/modules/users'
import { callInstructionsOptiMixin } from '@/mixins/callInstructionsOptiMixin'
import { keystonePathGenerator } from '@/mixins/keystonePathGenerator'
import { baseViewMixin } from '@/mixins/baseViewMixin'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent'

// load the store module dynamically only when needed
if (!store.state.users) store.registerModule('users', users)
if (!store.state.overlay) store.registerModule('overlay', overlay)

/**
 * id (io_id) for local (dev database) test
 * TTD : 7l2wpa8
 * APPX : 2860738
 * MM : 498343
 * DBM : 10682872
 */
export default {
  name: 'Model',
  mixins: [stratMixin, generalMixin, callInstructionsOptiMixin, keystonePathGenerator, baseViewMixin],
  props: {

  },
  components: {
    CopyClipboardComponent
  },
  data: function () {
    return {
      model: '',
      errors: '',
      loading: false,
      instructionInfo: {
        strat: {},
        opti: {}
      },
      clientId: null
    }
  },
  mounted: function () {
    this.onMounted()
    this.callApiModel()
  },
  methods: {
    async callApiModel () {
      this.errors = ''
      let dsp = this.dsp
      let modelId = this.$route.params.instructionStratId
      this.loading = true
      let response = await this.$apiCaller.getModelStrat(modelId, dsp)
      this.loading = false
      if (this.$apiCaller.isResponseError(response)) {
        let toPrint = this.$commonUtils.issetInObject(response, 'response.data.errors') ? response.response.data.errors : response
        this.errors = `Error when calling model : ${toPrint}`
      } else {
        this.model = this.getModelInResponse(response)
        this.instructionInfo.strat = response.data.info_strat.data
        this.instructionInfo.opti = response.data.instruction.data

        if (!this.instructionInfo.opti) {
          const insertionOrderId = this.instructionInfo.strat[this.getInsertionOrderPropertyPerIo]
          await this.callInstructionOpti(insertionOrderId)
          if (this.instructionsOpti.length > 0) {
            this.clientId = this.instructionsOpti[0][this.getClientPerDsp]
          }
        } else {
          this.clientId = this.instructionInfo.opti[this.getClientPerDsp]
        }
      }
    },
    getModelInResponse (response) {
      const model = response.data.model

      if ([this.$APPNEXUS].indexOf(this.dsp) !== -1) {
        return model.includes('genie_model') ? JSON.stringify(JSON.parse(model), null, 4) : model
      }
      return JSON.stringify(model, null, 4)
    }
  },
  computed: {
    displayError () {
      return this.errors !== ''
    },
    computedModel () {
      return this.model.replace(/".+"/g, (corr) => {
        return `<span class="green-code">${this.$commonUtils.htmlEntities(corr)}</span>`
      })
        .replace(/\\n/g, '<br>')
        .replace(/\\t/g, '&nbsp'.repeat(4))
        .replace(/elif /g, '<span class="red-code">elif </span>')
        .replace(/if /g, '<span class="red-code">if </span>')
        .replace(/ in /g, '<span class="red-code"> in </span>')
        .replace(/else:/g, '<span class="red-code">else</span>:')
        .replace(/\d/g, (corr) => {
          return `<span class="blue-code">${this.$commonUtils.htmlEntities(corr)}</span>`
        })
    }
  },
  watch: {

  }
}
</script>

<style scoped>
  .about {
    text-align: center;
    margin-top: 124px;
  }
  .model-container {
    white-space: pre;
    background: white;
    font-variant: all-petite-caps;
    padding: 2em;
  }

  .model-div {
    padding: 0.5em;
  }

  .title-model {
    background: white;
  }

  .model-main-container {
    background: white;
    padding: 2em;
  }

  .data-model-container {
    padding: 2em;
  }

  .loading-container {
    margin-top: 3em;
    text-align: center;
  }

  .align-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end !important;
  }

  .background-code {
    background: #2d2d2d;
    color: white;
    font-variant: none;
    overflow: auto;
    max-height: 34em;
  }
</style>

<style>
  .red-code {
    color: orangered;
  }

  .blue-code {
    color: cornflowerblue;
  }

  .green-code {
    color: green;
  }

  .green-code .blue-code {
    color: green;
  }
</style>
